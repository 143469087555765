import React from 'react'
import { Table, Button, Modal } from "flowbite-react";
import { useNavigate } from 'react-router-dom'
import { parseSqlDateNoHour } from '../../../utils/utils';


const ListAvailModal = ({ modalState, closeModal, avails }) => {
    const navigate = useNavigate()
    const deleteAvail = async (availId) => {
        const response = await fetch('https://api.planileo.fr/planning/deleteAvailability/'+ availId, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                closeModal()
                window.location.reload()
            } else {
                alert(result.message)
            }
        }
    }

    return (

        <Modal show={modalState}  onClose={() => closeModal()}>
            <Modal.Header>Vos disponibilités</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div>
                        <p className="font-small text-gray-600">Vous serez indiqué comme disponible pour les organismes sur ces dates</p>
                    </div>
                    <Table>
                        <Table.Head>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Début</Table.HeadCell>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Fin</Table.HeadCell>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                <span className="sr-only">Edit</span>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {avails && avails.length > 0 && avails.map((avail, index) => (
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-small text-gray-900 md:font-medium">
                                        {parseSqlDateNoHour(avail.startdate)}
                                    </Table.Cell>
                                    <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{parseSqlDateNoHour(avail.enddate)}</Table.Cell>
                                    <Table.Cell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                        <Button size="sm" color="failure" onClick={() => {deleteAvail(avail.id)} }>Supprimer</Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ListAvailModal;