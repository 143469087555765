
import { Sidebar } from "flowbite-react";
import { HiChartPie, HiOutlineUserGroup } from "react-icons/hi";
const SidebarMenu = () => {
    return (
        <Sidebar aria-label="Default sidebar example">
            <Sidebar.Items>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/admin/dashboard" icon={HiChartPie}>
                        Tableau de bord
                    </Sidebar.Item>
                    <Sidebar.Item href="/admin/formateurs" icon={HiOutlineUserGroup}>
                        Formateurs
                    </Sidebar.Item>
                    <Sidebar.Item href="/admin/organismes" icon={HiOutlineUserGroup}>
                        Organismes
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    );
}

export default SidebarMenu;