"use client";

import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";

const FilAriane = ({firstItem, secondItem}) => {
    return (
        <Breadcrumb aria-label="Solid background breadcrumb example" className="mb-4 ">
            <Breadcrumb.Item href="/" icon={HiHome}>
                Accueil
            </Breadcrumb.Item>
            <Breadcrumb.Item href={firstItem.url}>{firstItem.label}</Breadcrumb.Item>
            { secondItem && <Breadcrumb.Item>{secondItem.label}</Breadcrumb.Item> }
        </Breadcrumb>
    );
}
export default FilAriane;