import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import SidebarMenu from "./Sidebar";

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Header/>
            <div className="flex">
                <SidebarMenu/>
                <main className="w-[calc(100%-16rem)] px-12">{children}</main>
            </div>
        </React.Fragment>
    );
};
export default Layout;