import React, { useState, useEffect } from "react";
import { Alert, Label, TextInput, Button } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { useCookies } from 'react-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'

function ResetPassword() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['userInfo']);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")
    const userId = searchParams.get("id")

    useEffect( () => {
        if (cookies.userInfo) {
            alert("Vous êtes déjà connecté.e")
            navigate('/', { replace: true })
        } else {
            if( !token || token === "" || !userId || userId === "" ) {
                setErrorMessage("Ce lien n'est pas valide. Veuillez réessayer.")
                setIsError(true)
            } else {
                async function fetchData() {
                    console.log('fetch')
                    const response = await fetch('https://api.planileo.fr/users/checkResetPasswordUrl', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: token,
                            userId: userId
                        }),
                    });
                    if( response.status === 401 ) {
                        setErrorMessage("Ce lien n'est pas ou plus valide. Veuillez réessayer.")
                        setIsError(true)
                    } else if( response.status !== 200 ) {
                        alert('Une erreur est survenue. Veuillez réessayer plus tard.')
                    } else {
                        console.log(response)
                    }
                }
                fetchData()
            }
        }

    }, []);


    let handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== repeatPassword) {
            alert("Les deux mots de passe ne sont pas identiques")
            return
        }
        try {
            let res = await fetch("https://api.planileo.fr/users/resetForgottenPassword", {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    userId: userId,
                    password: password,
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                console.log(resJson)
                setIsError(false)
                setIsSuccess(true)
            } else {
                setErrorMessage("Une erreur est survenue. Réessayez plus tard.")
                setIsError(true)
            }
        } catch (err) {
            alert("Une erreur est survenue")
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            <div className="px-4 w-full max-w-lg mb-4">
                <h2 className="text-xl font-bold">Nouveau mot de passe</h2>
                <p className="mb-2 text-gray-500">Renseignez votre nouveau mot de passe ci-dessous.</p>
            </div>
            <form onSubmit={handleSubmit} className="flex mx-auto px-4 w-full max-w-lg flex-col gap-4">
                <Alert className={`${!isSuccess && "hidden"}`} color="success" icon={HiInformationCircle}>
                    <span className="font-medium">Mot de passe changé</span> Rendez-vous sur la <a className="underline" href="/login">page de connexion</a> pour vous connecter avec votre nouveau mot de passe
                </Alert>
                <Alert className={`${!isError && "hidden"}`} color="failure" icon={HiInformationCircle}>
                    <span className="font-medium">Erreur</span> {errorMessage}
                </Alert>
                <div className={`${isSuccess && "hidden"} ${isError && "hidden"}`}>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="password" value="Mot de passe" />
                        </div>
                        <TextInput id="password" onChange={(e) => setPassword(e.target.value)} type="password" autoComplete="on" required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="repeat-password" value="Confirmation du mot de passe" />
                        </div>
                        <TextInput id="repeat-password" onChange={(e) => setRepeatPassword(e.target.value)} type="password" autoComplete="on" required shadow />
                    </div>
                    <Button className="mt-6" type="submit">Réinitialiser le mot de passe</Button>
                </div>
            </form>
        </div>
    );
}
export default ResetPassword;