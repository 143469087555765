import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";
import { Button, Checkbox, Radio, Label, TextInput, Alert } from "flowbite-react";
import { useNavigate } from 'react-router-dom'


const RegisterForm = () => {
    const [email, setEmail] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [role, setRole] = useState("2");
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    let handleSubmit = async (e) => {
        e.preventDefault();
        if (password === repeatPassword) {
            try {
                let res = await fetch("https://api.planileo.fr/users/register", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                        role: role,
                        entreprise: company,
                        nom: nom,
                        prenom: prenom
                    }),
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.success === true) {
                        navigate('/login', { state:{justRegistered : true}} )
                    } else {
                        setIsError(resJson.message);
                    }
                } else {
                    setIsError("Une erreur ("+ res.status +") est survenue. Veuillez réessayer plus tard.");
                }
            } catch (err) {
                setIsError("Impossible de contacter le serveur. Veuillez réessayer plus tard.");
            }
        } else {
            setIsError("Les deux mots de passe ne sont pas égaux");
        }
    };

    return (
            <form onSubmit={handleSubmit} className="flex mx-auto px-4 w-full max-w-lg flex-col gap-4">
                <Alert className={`${!isError && "hidden"}`} color="failure" icon={HiInformationCircle}>
                    <span className="font-medium">Erreur</span> {isError}
                </Alert>
                <div>
                    <fieldset className="flex max-w-md flex-col gap-4">
                        <Label htmlFor="Role" value="Je m'inscris en tant que" />
                        <div className="flex items-center gap-2">
                            <Radio id="role-2" name="countries" value="2" defaultChecked onChange={e => { setRole(e.target.value) }} />
                            <Label htmlFor="united-state">Organisme de formation</Label>
                        </div>
                        <div className="flex items-center gap-2">
                            <Radio id="role-3" name="countries" value="3" onChange={e => { setRole(e.target.value) }} />
                            <Label htmlFor="germany">Formateur</Label>
                        </div>
                    </fieldset>
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="company" value="Entreprise" />
                    </div>
                    <TextInput id="company" onChange={(e) => setCompany(e.target.value)} type="text" placeholder="Nom de l'entreprise" required shadow />
                </div>
                {role === "3" && (
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="nom" value="Nom" />
                        </div>
                        <TextInput id="nom" onChange={(e) => setNom(e.target.value)} type="text" placeholder="Nom" required shadow />

                        <div className="mb-2 mt-4 block">
                            <Label htmlFor="prenom" value="Prénom" />
                        </div>
                        <TextInput id="prenom" onChange={(e) => setPrenom(e.target.value)} type="text" placeholder="Prénom" required shadow />
                    </div>
                )}
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email" value="Adresse email" />
                    </div>
                    <TextInput id="email" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="nom@exemple.com" required shadow />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password" value="Mot de passe" />
                    </div>
                    <TextInput id="password" onChange={(e) => setPassword(e.target.value)} type="password" autoComplete="on" required shadow />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="repeat-password" value="Confirmation du mot de passe" />
                    </div>
                    <TextInput id="repeat-password" onChange={(e) => setRepeatPassword(e.target.value)} type="password" autoComplete="on" required shadow />
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="agree" required />
                    <Label htmlFor="agree" className="flex">
                        J'ai lu et j'accepte la&nbsp;
                        <Link href="#" className="text-cyan-600 hover:underline dark:text-cyan-500">
                            politique de confidentialité
                        </Link>
                    </Label>
                </div>
                <Button type="submit">Créer on compte</Button>
            </form>
    );
}
export default RegisterForm;