import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { Button, Table, Modal, Label, TextInput, Alert, Avatar } from "flowbite-react";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import FilAriane from "../../components/FilAriane"

function FormMyAccount() {
    const [cookies] = useCookies(['userInfo'])
    const [user, setUser] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        getUserInfo();
    }, []);


    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setUser(res)
            console.log(res)
        }
    };

    return (
        <Layout>
            <div>
                <h3 className="text-xl font-semibold">Mon compte</h3>
                <p className="font-small text-gray-600">Vous pouvez consulter et modifier vos informations ci-dessous</p>
                <div className="mt-4 flex max-w-md flex-col gap-4">
                    <div>
                        <Label htmlFor="disabledInput2">Adresse email</Label>
                        <TextInput type="text" value={user.email} id="disabledInput2" disabled readOnly />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Type de compte</Label>
                        <TextInput type="text" value={user.role} id="disabledInput2" disabled readOnly />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Nom</Label>
                        <TextInput type="text" value={user.nom} placeholder="Votre nom" id="disabledInput2" disabled readOnly />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Prénom</Label>
                        <TextInput type="text" value={user.prenom} placeholder="Votre prénom" id="disabledInput2" disabled readOnly />
                    </div>
                </div>
                <Button className="mt-4">Enregistrer</Button>
            </div>
        </Layout>
    );
}
export default FormMyAccount;