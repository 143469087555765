import React, { useState } from 'react'
import { Button, Modal } from "flowbite-react"
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useCookies } from 'react-cookie'


const AskUpdateModal = ({ modalState, closeModal, form, updateIsSent }) => {
    const [cookies] = useCookies(['userInfo'])
    const [isLoading, setIsLoading] = useState(false);

    const sendMail = async (e) => {
        setIsLoading(true)
        const response = await fetch('https://api.planileo.fr/notifications/sendUpdatePlanning', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'orgId': cookies.userInfo.id,
                'formId': form
            }),
        });
        if (response.status !== 200) {
            alert('Le mail n\'a pas pu être envoyé. Réessayez plus tard.')
        } else {
            closeModal()
            updateIsSent()
        }
    }

    return (

        <Modal show={modalState} size="md" onClose={() => closeModal()} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Confirmez pour envoyer au formateur une demande de mise à jour de son planning
                    </h3>
                    <div className="flex justify-center gap-4">
                        {!isLoading ?
                            <Button color="blue" onClick={() => {sendMail()}}>{"Confirmer"}</Button>
                        : (
                            <Button color="gray">{"Envoi.."}</Button>
                        )}
           
                        <Button color="gray" onClick={() => closeModal()}>
                            Annuler
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default AskUpdateModal;