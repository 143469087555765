import React, { useEffect, useState } from 'react';
import RegisterForm from "./RegisterForm";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function Register() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['userInfo']);

    useEffect(() => {
        if (cookies.userInfo) {
            alert("Vous êtes déjà connecté.e")
            navigate('/', { replace: true })
        }
    }, []);

    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            <h2 className="mb-8 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">Inscription</h2>
            <RegisterForm />
        </div>
    );
}
export default Register;