import React, { useState, useEffect } from "react";

function NoPage() {
  return (
    <div>
      <h1>404</h1>
      <p>Pas de page ici</p>
    </div>
  );
}
export default NoPage;