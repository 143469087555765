import React, { useEffect, useState } from 'react'
import Layout from "./global/Layout"
import { Alert, Button, Modal } from "flowbite-react";
import timeGridPlugin from '@fullcalendar/timegrid'
import daygridPlugin from "@fullcalendar/daygrid"
import FullCalendar from '@fullcalendar/react'
import frLocale from '@fullcalendar/core/locales/fr'
import { useNavigate } from 'react-router-dom'
import FilAriane from "../../components/FilAriane"
import { useCookies } from 'react-cookie'


function OrgMonPlanning() {
    const [options, setOptions] = useState([]);
    const [eventModal, setEventModal] = useState(false);
    const [currEvent, setCurrEvent] = useState([]);
    const navigate = useNavigate()
    const [cookies] = useCookies(['userInfo'])
    var calendarInitial
    window.innerWidth > 768 ? calendarInitial = "timeGridWeek" : calendarInitial = "timeGridDay"


    useEffect(() => {
        getOptions();
    }, []);
    const getOptions = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getOrgOptions/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            var results = []
            const result = await response.json();
            for (const option of result.options) {
                const formateur = option.formPrenom + ' ' + option.formNom;
                if (option.etat === 1) { //option validée formateur
                    results.push({ title: 'Option | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Formation", status: 'valid_org', id: option.id, formateur: formateur, formId: option.formId, commentaire: option.commentaire }, backgroundColor: "#F8D21B", borderColor: "#F8D21B" })
                } else if (option.etat === 0) { //option posée
                    results.push({ title: 'Option | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Option", status: 'valid_form', id: option.id, formateur: formateur, formId: option.formId, commentaire: option.commentaire }, backgroundColor: "#F87F1B", borderColor: "#F87F1B" })
                } else { // option validée par l'organisme
                    results.push({ title: 'Formation | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Option", status: 'confirmed', id: option.id, formateur: formateur, formId: option.formId, commentaire: option.commentaire }, backgroundColor: "#88CA0B", borderColor: "#88CA0B" })
                }
            }
            setOptions(results);
        }
    };

    const eventClick = (el) => {
        setEventModal(true)
        setCurrEvent(el.event._def.extendedProps)
    };


    return (
        <Layout>
            <FilAriane firstItem={{ label: "Mon planning", url: "/org/on-planning" }} />
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <h3 className="text-l font-semibold md:text-xl">Mes réservations</h3>
                    <p className="text-sm text-gray-600 md:text-lg">Toutes vos options et formations sont visibles ici.</p>
                </div>
            </div>
            <div className='mb-6'>
                <FullCalendar
                    plugins={[daygridPlugin, timeGridPlugin]}
                    editable
                    selectable
                    allDaySlot={false}
                    headerToolbar={{
                        start: "today prev next title",
                        end: "dayGridMonth timeGridWeek dayGridDay",
                    }}
                    events={options}
                    slotMinTime={'06:00:00'}
                    slotMaxTime={'20:00:00'}
                    height={'auto'}
                    locale={frLocale}
                    eventClick={eventClick}
                    views={["dayGridMonth", "timeGridWeek", "dayGridDay"]}
                    initialView={calendarInitial}
                />
            </div>
            <Modal dismissible show={eventModal} onClose={() => setEventModal(false)}>
                <Modal.Header>{currEvent.type}</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        {currEvent.status === "valid_form" && 
                            <><Alert color="warning" rounded>Le formateur {currEvent.formateur} n'a pas encore accepté l'option.</Alert>
                            {currEvent.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire à l'attention du formateur : {currEvent.commentaire}</p>}</>
                        }
                        {currEvent.status === "valid_org" && 
                            <><Alert color="warning" rounded>Le formateur a validé ce créneau de formation. attente de votre validation finale.</Alert>
                            <p className="font-small text-gray-900 mt-4">Confirmez ce créneau sur le planning du formateur pour planifier une formation</p>
                            {currEvent.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire à l'attention du formateur : {currEvent.commentaire}</p>}</>
                        }
                        {currEvent.status === "confirmed" && 
                            <><Alert color="success" rounded>Une formation avec <span className="font-medium">{currEvent.formateur}</span> est prévue sur ce créneau</Alert>
                            {currEvent.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire à l'attention du formateur : {currEvent.commentaire}</p>}</>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => navigate('/org/planning-formateur/'+ currEvent.formId, { replace: true })}>Aller sur le planning du formateur</Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
}
export default OrgMonPlanning;