import React, { useState, useEffect } from "react";
import { Alert, Label, TextInput, Button } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function ForgotPassword() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['userInfo']);
    const [isSent, setIsSent] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (cookies.userInfo) {
            alert("Vous êtes déjà connecté.e")
            navigate('/', { replace: true })
        }
    }, []);


    let handleSubmit = async (e) => {
        e.preventDefault();
        setIsSent(true)
        try {
            let res = await fetch("https://api.planileo.fr/users/resetPasswordEmail", {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                console.log(resJson)
            } else {
                alert('Une erreur est survenue')
            }
        } catch (err) {
            alert("Une erreur est survenue")
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            <div className="px-4 w-full max-w-lg mb-4">
                <h2 className="text-xl font-bold">Récupération de votre mot de passe</h2>
                <p className="mb-2 text-gray-500">Renseignez votre adresse email ci-dessous pour recevoir un lien de réinitialisation de votre mot de passe.</p>
            </div>
            <form onSubmit={handleSubmit} className="flex mx-auto px-4 w-full max-w-lg flex-col gap-4">
                <Alert className={`${!isSent && "hidden"}`} color="success" icon={HiInformationCircle}>
                    <span className="font-medium">Vérifiez votre boite mail.</span> Vous avez reçu un email si l'email que vous avez renseigné existe dans Planileo
                </Alert>
                <div className={`${isSent && "hidden"}`}>
                    <div className="mb-4">
                        <div className="mb-2 block">
                            <Label htmlFor="email" value="Votre adresse email" />
                        </div>
                        <TextInput id="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="nom@gmail.com" required />
                    </div>
                    <Button type="submit">Envoyer le lien de récupération</Button>
                </div>
            </form>
        </div>
    );
}
export default ForgotPassword;