import React from 'react';
import { Sidebar, Drawer } from "flowbite-react";
import { HiOutlineCalendar, HiChartPie, HiViewBoards, HiUsers } from "react-icons/hi";
import { BiBuoy } from "react-icons/bi";


const SidebarMenu = ({ sidebarOpen, closeSidebar }) => {
    let width = window.innerWidth;
    return (
        <>
            {width > 1024 ? (
                <Sidebar className="hidden lg:block" aria-label="Default sidebar">
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item className="active" href="/org/tableau-de-bord" icon={HiChartPie}>
                                Tableau de bord
                            </Sidebar.Item>
                            <Sidebar.Item href="/org/mes-formateurs" icon={HiUsers}>
                                Mes Formateurs
                            </Sidebar.Item>
                            <Sidebar.Item href="/org/mon-planning" icon={HiOutlineCalendar}>
                                Mes réservations
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item href="#" icon={HiViewBoards}>
                                Documentation
                            </Sidebar.Item>
                            <Sidebar.Item href="#" icon={BiBuoy}>
                                Besoin d'aide ?
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            ) : (
                <Drawer className="lg:hidden" open={sidebarOpen} onClose={closeSidebar}>
                    <Drawer.Header title="MENU" titleIcon={() => <></>} />
                    <Drawer.Items>
                        <Sidebar aria-label="Default sidebar">
                            <Sidebar.Items>
                                <Sidebar.ItemGroup>
                                    <Sidebar.Item className="active" href="/org/tableau-de-bord" icon={HiChartPie}>
                                        Tableau de bord
                                    </Sidebar.Item>
                                    <Sidebar.Item href="/org/mes-formateurs" icon={HiUsers}>
                                        Mes Formateurs
                                    </Sidebar.Item>
                                    <Sidebar.Item href="/org/mon-planning" icon={HiOutlineCalendar}>
                                        Mon Planning
                                    </Sidebar.Item>
                                </Sidebar.ItemGroup>
                                <Sidebar.ItemGroup>
                                    <Sidebar.Item href="#" icon={HiViewBoards}>
                                        Documentation
                                    </Sidebar.Item>
                                    <Sidebar.Item href="#" icon={BiBuoy}>
                                        Besoin d'aide ?
                                    </Sidebar.Item>
                                </Sidebar.ItemGroup>
                            </Sidebar.Items>
                        </Sidebar>
                    </Drawer.Items>
                </Drawer>
            )}
        </>
    );
}




export default SidebarMenu;