import React, { useState } from 'react';
import Header from '../../../components/Header';
import SidebarMenu from "./Sidebar";

const Layout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const closeSidebar = () => {
        setSidebarOpen(false)
    }
    const openSidebar = () => {
        setSidebarOpen(true)
    }

    return (
        <React.Fragment>
            <Header openSidebar={openSidebar} />
            <div className="flex h-screen">
                <SidebarMenu sidebarOpen={sidebarOpen} closeSidebar={closeSidebar}/>
                <div className="w-full px-4 pb-4 lg:w-[calc(100%-16rem)] lg:px-12">{children}</div>
            </div>
        </React.Fragment>
    );
};
export default Layout;