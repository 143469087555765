
import React, { Component } from "react";
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom'

const AdminAuth = ({ children }) => {
    const [cookies] = useCookies(['userInfo']);

    var isAuthenticated = true;
    if( !cookies.userInfo || cookies.userInfo.roleId !== 1 ) {
        isAuthenticated = false;
    } 
    if (isAuthenticated ) {
        return children;  
    } else {
        return <Navigate to="/login" replace />
    }
};

const FormateurAuth = ({ children }) => {
    const [cookies] = useCookies(['userInfo']);

    var isAuthenticated = true;
    if( !cookies.userInfo || cookies.userInfo.roleId !== 3 ) {
        isAuthenticated = false;
    } 
    
    if (isAuthenticated ) {
        return children;  
    } else {
        return <Navigate to="/login" replace />
    }
};

const OrganismeAuth = ({ children }) => {
    const [cookies] = useCookies(['userInfo']);

    var isAuthenticated = true;
    if( !cookies.userInfo || cookies.userInfo.roleId !== 2 ) {
        isAuthenticated = false;
    } 
    if (isAuthenticated ) {
        return children;  
    } else {
        return <Navigate to="/login" replace />
    }
};

export {AdminAuth, FormateurAuth, OrganismeAuth};